var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"test",rawName:"v-test:dropTarget",value:(_vm.item.id !== null ? _vm.item.id : false),expression:"item.id !== null ? item.id : false",arg:"dropTarget"}],staticClass:"folder-item sidebar-item",class:{
    'folder-item--compact': _vm.isCompactView,
    'folder-item--is-active': _vm.isActive,
  },attrs:{"data-drop-target":_vm.item.id !== null ? _vm.item.id : 0}},[_c('a',{directives:[{name:"test",rawName:"v-test:folderItem",value:(_vm.isActive ? 'is-active' : 0),expression:"isActive ? 'is-active' : 0",arg:"folderItem"}],staticClass:"button sidebar-item__link folder-item__link",class:{
      'sidebar-item__link--is-active': _vm.isActive,
      'folder-item__link--is-compact': _vm.isCompactView,
    },attrs:{"href":_vm.getUrl(_vm.folderRoute),"title":_vm.folderName},on:{"click":function($event){$event.preventDefault();return _vm.$emit('folderItemClicked')}}},[_c('span',{staticClass:"sidebar-item__content",attrs:{"title":_vm.item.name}},[_c('transition',{attrs:{"name":"grow-element"}},[(
            _vm.item.unread_messages_count && !_vm.sidebarIsOpen && _vm.shownInSidebar
          )?_c('span',{staticClass:"folder-item__unread-dot"}):_vm._e()]),_c('Icon',{staticClass:"sidebar-item__icon",class:{ 'sidebar-item__icon--visible': _vm.isActive },attrs:{"symbol":(_vm.icon + "-active")}}),_c('Icon',{staticClass:"sidebar-item__icon",class:{ 'sidebar-item__icon--visible': !_vm.isActive },attrs:{"symbol":_vm.icon}}),_c('transition',{attrs:{"name":"fade"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFullName),expression:"showFullName"}],staticClass:"sidebar-item__name"},[_vm._v(" "+_vm._s(_vm.folderName)),(_vm.showCurrentTag && _vm.isActive)?_c('span',[_vm._v(" ("),_c('translate',[_vm._v("Current")]),_vm._v(")")],1):_vm._e()])])],1)]),(_vm.isActive && _vm.shownInSidebar && _vm.showFullName)?_c('button',{directives:[{name:"test",rawName:"v-test:folderItemReload",arg:"folderItemReload"}],staticClass:"folder-item__reload-button button button--icon-only",class:{ 'is-loading': _vm.isLoading || _vm.isBusy.reloadingMessages },attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.reloadMessages(_vm.getFolderName(_vm.item))}}},[_c('Icon',{attrs:{"symbol":"refresh"}}),_c('translate',{staticClass:"visually-hidden"},[_vm._v("Refresh")])],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.count && _vm.shownInSidebar && _vm.showFullName)?_c('a',{directives:[{name:"test",rawName:"v-test:count",arg:"count"}],staticClass:"sidebar-item__count folder-item__count",class:{ 'sidebar-item__count--is-active': _vm.isActive },attrs:{"href":_vm.getUrl(_vm.folderRoute),"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('folderItemClicked')}}},[_vm._v(" "+_vm._s(_vm.count)+" ")]):_vm._e()]),_vm._t("settingsActionsForFolder",null,{"item":_vm.item})],2)}
var staticRenderFns = []

export { render, staticRenderFns }