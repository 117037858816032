import Vue from 'vue';

export default Vue.extend({
  methods: {
    groupByFirstCharacter<T>(
      items: T[],
      unnamed: string,
      groupBy: (item: T) => string
    ) {
      return items.reduce((grouped: { [key: string]: T[] }, item) => {
        const groupByValue = groupBy(item);
        const firstCharacter = groupByValue.charAt(0).toUpperCase();
        const unaccentedFirstCharacter = firstCharacter
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        const groupLetter = unaccentedFirstCharacter.match(/[A-Z]/)
          ? unaccentedFirstCharacter
          : unnamed;
        grouped[groupLetter] = grouped[groupLetter] || [];
        grouped[groupLetter].push(item);
        return grouped;
      }, {});
    },
  },
});
