import { render, staticRenderFns } from "./NestedFoldersItem.vue?vue&type=template&id=29f602c6&"
import script from "./NestedFoldersItem.vue?vue&type=script&lang=js&"
export * from "./NestedFoldersItem.vue?vue&type=script&lang=js&"
import style0 from "./NestedFoldersItem.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports