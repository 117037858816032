

















































  import Icon from '@/components/Icon/Icon.vue';
  import { mapGetters } from 'vuex';
  import Vue from 'vue';
  import { Location } from 'vue-router';

  export default Vue.extend({
    name: 'SidebarItem',
    components: {
      Icon,
    },
    inheritAttrs: false,
    props: {
      route: {
        type: Object,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        required: true,
      },
      count: {
        type: Number,
        required: false,
        default: 0,
      },
      alternative: {
        type: Boolean,
        required: false,
      },
    },
    computed: {
      ...mapGetters(['sidebarIsOpen']),
      ...mapGetters('layout', ['isCompactView']),
      isActive(): boolean {
        return this.$route.path === this.getUrl(this.route);
      },
    },
    methods: {
      getUrl(route: Location): string {
        return this.$router.resolve(route).resolved.fullPath;
      },
    },
  });
