<template>
  <Modal :loading="busyMoving" show-loading-spinner>
    <template v-slot:toggle="modal">
      <slot name="toggle" :toggle="modal.toggle">
        <ActionBarButton
          class="button--subtle button--small"
          v-test:moveToFolderButton
          :button-text="buttonText"
          v-tooltip="{ content: moveText }"
          icon="folder-move"
          @click="modal.toggle"
          :disabled="disabled"
        />
      </slot>
    </template>
    <template v-slot:header>
      <translate>Move to folder</translate>
    </template>
    <template v-slot:content="modal">
      <ul class="link-list">
        <slot name="additionalMoveTargets" :modal="modal" />
        <NestedFoldersItem
          v-for="folder in folderTree"
          :folder="folder"
          :key="folder.id"
          class="modal-folder-item"
          :has-loading-buttons="false"
          @folderItemClicked="
            onMoveToFolderClicked(arguments[0] || folder, modal)
          "
          :show-current-tag="true"
        />
      </ul>

      <ModalConfirmActions hide-ok @cancelClicked="modal.toggle" />
    </template>
  </Modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Modal from '@/components/Modal';
  import ActionBarButton from '@/components/ActionBarButton/ActionBarButton';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';

  export default {
    components: {
      Modal,
      // import component like this, because the component is recursive see: https://vuejs.org/v2/guide/components.html#Recursive-Components
      NestedFoldersItem: () =>
        import('@/components/NestedFoldersItem/NestedFoldersItem'),
      ActionBarButton,
      ModalConfirmActions,
    },
    mixins: [folderUtilsMixin],
    props: {
      buttonText: {
        type: String,
        required: false,
        default: '',
      },
      activeFolderId: {
        type: String,
        required: false,
        default: '',
      },
      busyMoving: {
        type: Boolean,
        required: false,
      },
      disabled: {
        type: Boolean,
        required: false,
      },
    },
    computed: {
      ...mapGetters(['folderTree']),
      moveText() {
        return this.$gettext('Move');
      },
    },
    methods: {
      onMoveToFolderClicked(folder, modal) {
        this.$emit('moveToFolder', folder.id, modal);
      },
    },
  };
</script>
<style src="./MoveToFolderModal.scss" lang="scss"></style>
